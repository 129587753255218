<template>
	<div class="RPA">
		<h2>RPA测试</h2>
		<main>
			<div class="box">
				<a-input v-model="url" />
				<a-textarea class="textarea" v-model="json" />
			</div>

			<a-button @click="run">执行</a-button>
		</main>
	</div>
</template>

<script>
import RunTask from "@/utils/runTask.js";
export default {
	components: {},
	name: "RpaTest",
	data() {
		return {
			json: "",
			url: "ws://127.0.0.1:53297/devtools/browser/2b4b254c-d5c9-4ddc-817b-74c10603df50",
		};
	},
	methods: {
		async run() {
			const task_list = JSON.parse(this.json);
			const runTask = new RunTask(this.url, task_list);
			await runTask.run();
			console.log("Done");
		},
	},
};
</script>
<style lang="less" scoped>
.RPA {
	padding: 0 60px;
	main {
		display: flex;
		align-items: flex-start;
	}
	.box {
		width: 500px;
		margin-right: 20px;
	}
	.ant-input.textarea {
		width: 500px;
		height: 300px;
		margin-top: 20px;
	}
}
</style>
